html,
body {
  height: 100%;
}

body {
  background-color: #170000;
  color: #fff;
}

/* ログイン */

#form-signin-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

#form-signin-container .form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

#form-signin-container .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

#form-signin-container .form-label-group > input,
#form-signin-container .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

#form-signin-container .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #ccc;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

#form-signin-container .form-label-group input,
.form-label-group input:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

#form-signin-container input:-webkit-autofill,
#form-signin-container input:-webkit-autofill:hover,
#form-signin-container input:-webkit-autofill:focus #form-signin-container textarea:-webkit-autofill,
#form-signin-container textarea:-webkit-autofill:hover #form-signin-container textarea:-webkit-autofill:focus,
#form-signin-container select:-webkit-autofill,
#form-signin-container select:-webkit-autofill:hover,
#form-signin-container select:-webkit-autofill:focus {
  border-color: rgba(255, 255, 255, 0.3);
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.1) inset;
  transition: background-color 5000s ease-in-out 0s;
}

#form-signin-container .form-label-group input::placeholder {
  color: transparent;
}

#form-signin-container .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

#form-signin-container .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #eee;
}

/* 注文テーブル */

#orders {
  font-family: 'DIN Alternate', sans-serif;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 0.9rem;
}

#navigator footer {
  text-align: right;
}

#navigator footer .btn-link {
  color: #fff;
  font-size: 11px;
}

/* デリバリーカード */
.delivery-card {
  border-left-width: 8px;
  border-left-style: solid;
}

.delivery-card:nth-of-type(5n + 1) {
  border-left-color: #6edff6;
}
.delivery-card:nth-of-type(5n + 2) {
  border-left-color: #ea868f;
}
.delivery-card:nth-of-type(5n + 3) {
  border-left-color: #ffda6a;
}
.delivery-card:nth-of-type(5n + 4) {
  border-left-color: #79dfc1;
}
.delivery-card:nth-of-type(5n + 5) {
  border-left-color: #feb272;
}
